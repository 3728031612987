import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class CategoryPage extends React.Component {
	render() {
	const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
	
  return ( 
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={'Category: ' + data.allMdx.group[0].tag} />
		          <header>
            <h1
              style={{
                marginTop: rhythm(1),
                marginBottom: 0,
              }}
            >
              {'Category: ' + data.allMdx.group[0].tag}
            </h1>
            <p
              style={{
                
                display: `block`,
                marginBottom: rhythm(1),
              }}
            >
			{data.allMdx.group[0].totalCount} Posts in Category
            </p>
          </header>
		  
        {data.allMdx.group[0].nodes.map(({ id, frontmatter, fields, excerpt }) => {
          const title = frontmatter.title || fields.slug
          return (
            <article key={id}>
              <header>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={fields.slug}>
                    {title}
                  </Link>
                </h3>
                <small>{frontmatter.date}</small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html:frontmatter.description || excerpt,
                  }}
                />
              </section>
            </article>
          )
        })}
		<hr />
		<Bio />
      </Layout>
  )
	}
}

export default CategoryPage

export const pageQuery = graphql`
  query ShowCategoryQuery($category: String) {
  site {
      siteMetadata {
        title
      }
    }
  allMdx(filter: {frontmatter: {category: {eq: $category}}}) {
	group(field: frontmatter___category) {
	  tag: fieldValue
	  totalCount
	  nodes {
		id
		excerpt
		fields {
		  slug
		}
		frontmatter {
		  title
		  date(formatString: "MMMM DD, YYYY")
		  description
		}
	  }
	}
  }
}	
`